import { Box } from '@mui/material';

import { ItemFieldList, RemarkMsgs } from '@components/_index';
import { InfosLayout } from '@layouts/_index';

import './AmountInfos.scss';

export default function AmountInfos({
    infosTitle,
    leftInfos,
    tradingData,
    remarkMsgs
}) {
    return (
        <InfosLayout infosTitle={infosTitle} className="amountInfosWrapper">
            <Box className="infosWrapper">
                <Box className="mainInfosItemsWrapper">
                    <Box className="leftInfos">
                        <ItemFieldList
                            listInfos={leftInfos}
                            fieldData={tradingData}
                        />
                    </Box>
                </Box>
                <RemarkMsgs remarkMsgs={remarkMsgs} />
            </Box>
        </InfosLayout>
    );
}
