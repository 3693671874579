import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import {
    AmountInfos,
    CancelTimerInfos,
    RecipientInfos,
    TotalAmountInfos,
    TraderInfos
} from '@components/_index';

import useAmountInfos from '@hooks/infos/useAmountInfos';

import '@template/styles/common/Paying.scss';

function SellPaying() {
    const { t } = useTranslation();
    const { infosTitle, leftInfos, tradingData, remarkMsgs } = useAmountInfos();
    const totalAmountInfosRemarkMsgs = [t('buyProcess.paying.noticeMsgAmount')];
    return (
        <Box className="wrapper sellPaying">
            <Box className="mainInfosWrapper">
                <Box className="infosGroupWrapper infosGroupWrapperAlpha">
                    <CancelTimerInfos />
                    {/* <CompanyInfos /> */}
                    <AmountInfos
                        infosTitle={infosTitle}
                        leftInfos={leftInfos}
                        tradingData={tradingData}
                        remarkMsgs={remarkMsgs}
                    />
                    <TotalAmountInfos
                        dataField="realAmount"
                        remarkMsgs={totalAmountInfosRemarkMsgs}
                    />
                </Box>
                <Box className="infosGroupWrapper infosGroupWrapperBeta">
                    <TraderInfos />
                    <RecipientInfos />
                </Box>
            </Box>
        </Box>
    );
}

export default SellPaying;
