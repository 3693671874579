import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import {
    ConfirmInfos,
    InfosHeader,
    TotalAmountInfos
} from '@components/_index';

import '@template/styles/common/Paying.scss';

import './BuyConfirming.scss';

export default function BuyConfirming() {
    const { t } = useTranslation();
    const totalAmountInfosRemarkMsgs = [t('buyProcess.paying.noticeMsgAmount')];
    return (
        <Box className="wrapper buyConfirming">
            <Box className="mainInfosWrapper">
                <InfosHeader />
                <Box className="infosGroupWrapper infosGroupWrapperBeta">
                    <ConfirmInfos />
                    <TotalAmountInfos
                        dataField="realAmount"
                        remarkMsgs={totalAmountInfosRemarkMsgs}
                    />
                </Box>
            </Box>
        </Box>
    );
}
