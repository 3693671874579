import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box } from '@mui/material';

import {
    ConfirmInfos,
    InfosHeader,
    TotalAmountInfos
} from '@components/_index';

import { RESULT_STATUS } from '@constants/constants';

export default function Resulted() {
    const { t } = useTranslation();
    const { tradingData } = useSelector((state) => state.order);
    const totalAmountInfosRemarkMsgs = [t('buyProcess.paying.noticeMsgAmount')];

    return (
        <Box className="wrapper resultedWrapper resultedWrapperThreeHoldConfirming">
            <Box className="mainInfosWrapper">
                <InfosHeader />
                {tradingData.status !== RESULT_STATUS.ORDER_ERROR && (
                    <Box className="infosGroupWrapper infosGroupWrapperBeta">
                        <ConfirmInfos />
                        <TotalAmountInfos
                            dataField="realAmount"
                            remarkMsgs={totalAmountInfosRemarkMsgs}
                        />
                    </Box>
                )}
            </Box>
        </Box>
    );
}
