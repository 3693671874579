export const paletteMui = {
    primary: {
        main: '#000000',
        contrastText: '#ffffff'
    },
    secondary: {
        main: '#ffffff',
        contrastText: '#000000'
    }
};

export const paletteCustomize = {
    ...paletteMui,
    imgOutline: '#000000'
};
