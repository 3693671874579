import { Box } from '@mui/material';

import { ItemFieldList } from '@components/_index';
import { InfosLayout } from '@layouts/_index';
import '@template/components/InfosForm/TraderInfos/TraderInfos.scss';

import useTraderInfos from './hook';

export default function TraderInfos() {
    const { tradingData, infosTitle, traderInfos } = useTraderInfos();

    return (
        <InfosLayout className="traderInfosWrapper" infosTitle={infosTitle}>
            <Box className="infosWrapper">
                <Box className="leftInfos">
                    <ItemFieldList
                        listInfos={traderInfos}
                        fieldData={tradingData}
                    />
                </Box>
            </Box>
        </InfosLayout>
    );
}
