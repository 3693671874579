import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { TRANSACTION_TYPE } from '@constants/constants';

export default function useRecipientInfos() {
    const { t } = useTranslation();
    const { transactionType } = useParams();
    const { tradingData } = useSelector((state) => state.order);

    const infosTitle = t(
        transactionType.toUpperCase() === TRANSACTION_TYPE.BUY
            ? 'common.orderInfos.sellerName'
            : 'common.orderInfos.buyerName'
    );

    let traderInfos = [
        {
            fieldLabel: 'sellerName',
            fieldName: '',
            isValueBold: true,
            isShowCopyBtn: true,
            tagNames: [
                t('common.orderInfos.accountVerifyTag.autonym'),
                t('common.orderInfos.accountVerifyTag.guarantee'),
                t('common.orderInfos.accountVerifyTag.quickConfirm')
            ]
        }
    ];

    return {
        tradingData,
        transactionType,
        infosTitle,
        traderInfos
    };
}
