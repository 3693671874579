import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import {
    AmountInfos,
    CancelTimerInfos,
    KycVerificationInfos,
    RecipientInfos,
    SmsVerificationInfos,
    TotalAmountInfos,
    TraderInfos
} from '@components/_index';

import useAmountInfos from '@hooks/infos/useAmountInfos';

import '@template/styles/common/Paying.scss';

export default function BuyPaying() {
    const { t } = useTranslation();
    const { infosTitle, leftInfos, rightInfos, tradingData, remarkMsgs } =
        useAmountInfos();
    const totalAmountInfosRemarkMsgs = [t('buyProcess.paying.noticeMsgAmount')];
    return (
        <Box className="wrapper buyPaying">
            <Box className="mainInfosWrapper">
                <Box className="infosGroupWrapper infosGroupWrapperAlpha">
                    <CancelTimerInfos />
                    {/* <CompanyInfos /> */}
                    <AmountInfos
                        infosTitle={infosTitle}
                        leftInfos={leftInfos}
                        rightInfos={rightInfos}
                        tradingData={tradingData}
                        remarkMsgs={remarkMsgs}
                        isShowTimerCancel={false}
                    />
                    <TotalAmountInfos
                        dataField="realAmount"
                        remarkMsgs={totalAmountInfosRemarkMsgs}
                    />
                </Box>
                <Box className="infosGroupWrapper infosGroupWrapperBeta">
                    {tradingData.needSmsVerify &&
                    tradingData.verifyStatus === 0 ? (
                        <SmsVerificationInfos />
                    ) : tradingData.needSelfInput &&
                      tradingData.verifyStatus === 0 ? (
                        <KycVerificationInfos />
                    ) : (
                        <>
                            <TraderInfos />
                            <RecipientInfos />
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    );
}
