import { Box } from '@mui/material';

import {
    AmountInfos,
    CancelTimerInfos,
    QRBoardInfos,
    TotalAmountInfos
} from '@components/_index';

import useAddressInfos from '@hooks/infos/useAddressInfos';

import '@template/styles/common/Paying.scss';

export default function BuyPayingManual() {
    const {
        infosTitle,
        leftInfos,
        rightInfos,
        tradingData,
        remarkMsgs,
        isShowBuyAddressInfosCountField
    } = useAddressInfos({
        isShowAddress: false,
        isHightLightUnitAmount: true,
        isShowRemarkMsgs: true
    });
    return (
        <Box className="wrapper buyPayingManual">
            <Box className="mainInfosWrapper">
                <Box className="infosGroupWrapper infosGroupWrapperAlpha">
                    <CancelTimerInfos />
                    {/* <CompanyInfos /> */}
                    <QRBoardInfos />
                </Box>
                <Box className="infosGroupWrapper infosGroupWrapperBeta">
                    <AmountInfos
                        infosTitle={infosTitle}
                        leftInfos={leftInfos}
                        rightInfos={rightInfos}
                        tradingData={tradingData}
                        remarkMsgs={remarkMsgs}
                        isShowTimerCancel={true}
                    />
                    {isShowBuyAddressInfosCountField && (
                        <TotalAmountInfos
                            fieldLabel="unitCount"
                            dataField="unit"
                            remarkMsgs={remarkMsgs}
                        />
                    )}
                </Box>
            </Box>
        </Box>
    );
}
