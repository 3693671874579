import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import useStepper from '@components/Stepper/hook';
import { InfosLayout } from '@layouts/_index';

import './Stepper.scss';

export default function Stepper({ stepNames, currStep }) {
    const { t } = useTranslation();

    const { currStepIndex, camelStepNames } = useStepper(stepNames, currStep);

    return (
        <Box className="stepper">
            {/* <Box className="stepperLeft"> <BrandLogoInfos /> </Box> */}
            <Box className="stepperLeft">
                <InfosLayout className="stepIndexWrapper">
                    <Box className="infosWrapper">
                        <Box className="stepIndexTitle">
                            {t('common.step.name')}
                        </Box>
                        <Box className="stepIndex">
                            {`${currStepIndex + 1}/${stepNames.length}`}
                        </Box>
                    </Box>
                </InfosLayout>
            </Box>
            <Box className="stepperRight">
                <InfosLayout className="stepNameWrapper">
                    <Box className="infosWrapper">
                        <Box className="stepName">
                            {t('common.step.' + camelStepNames[currStepIndex])}
                        </Box>
                    </Box>
                </InfosLayout>
            </Box>
        </Box>
    );
}
