import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { Box } from '@mui/material';

import { AmountInfos, InfosHeader, TotalAmountInfos } from '@components/_index';

import { RESULT_STATUS, TRANSACTION_TYPE } from '@constants/constants';
import useAddressInfos from '@hooks/infos/useAddressInfos';

export default function Resulted() {
    const { transactionType } = useParams();
    const { tradingData } = useSelector((state) => state.order);
    const { infosTitle, leftInfos, rightInfos, remarkMsgs } = useAddressInfos({
        isShowAddress: false,
        isHightLightUnitAmount: false,
        isShowRemarkMsgs: false
    });

    return (
        <Box className="wrapper resultedWrapper resultedWrapperTwoSimple">
            <Box className="mainInfosWrapper">
                <InfosHeader />
                {tradingData.status !== RESULT_STATUS.ORDER_ERROR && (
                    <Box className="infosGroupWrapper infosGroupWrapperBeta">
                        <AmountInfos
                            infosTitle={infosTitle}
                            leftInfos={leftInfos}
                            rightInfos={rightInfos}
                            tradingData={tradingData}
                            remarkMsgs={remarkMsgs}
                            isShowTimerCancel={false}
                        />
                        {transactionType.toUpperCase() ===
                            TRANSACTION_TYPE.SELL && (
                            <TotalAmountInfos
                                fieldLabel="unitCount"
                                dataField="count"
                            />
                        )}
                    </Box>
                )}
            </Box>
        </Box>
    );
}
