import { Box } from '@mui/material';

import { TimerCancel } from '@components/_index';
import { InfosLayout } from '@layouts/_index';
import '@template/components/InfosForm/CancelTimerInfos/CancelTimerInfos.scss';

import '@styles/common/CancelTimerInfos.scss';

export default function CancelTimerInfos() {
    return (
        <InfosLayout className="cancelTimerInfosWrapper">
            <Box className="infosWrapper">
                <Box className="leftInfos">
                    <TimerCancel />
                </Box>
            </Box>
        </InfosLayout>
    );
}
