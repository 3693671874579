import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import ItemNumericUnit from '@components/ItemFieldPart/ItemNumericUnit/ItemNumericUnit';

import { TRANSACTION_TYPE } from '@constants/constants';

export default function useTotalAmountInfos({ dataField, fieldLabel }) {
    const { t } = useTranslation();
    const { transactionType } = useParams();
    const { tradingData } = useSelector((state) => state.order);

    const infosTitle =
        transactionType.toUpperCase() === TRANSACTION_TYPE.BUY
            ? t('buyProcess.realAmount')
            : t('sellProcess.realAmount');

    const leftInfos = [
        {
            fieldLabel: fieldLabel ?? dataField,
            fieldName: '',
            fieldValue:
                tradingData.legalCurrency + ' ' + tradingData[dataField],
            renderFieldValue: () =>
                ItemNumericUnit({
                    numeric: tradingData.realAmount,
                    currency: tradingData.legalCurrency,
                    isShowUnit: true
                }),
            isShowCopyBtn: true
        }
    ];

    return {
        tradingData,
        infosTitle,
        leftInfos
    };
}
