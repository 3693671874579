import { Box } from '@mui/material';

import { ItemFieldList, RemarkMsgs } from '@components/_index';
import { InfosLayout } from '@layouts/_index';

import './TotalAmountInfos.scss';
import useTotalAmountInfos from './hook';

export default function TotalAmountInfos({
    dataField,
    fieldLabel,
    remarkMsgs = []
}) {
    const { tradingData, infosTitle, leftInfos } = useTotalAmountInfos({
        dataField,
        fieldLabel
    });
    return (
        <InfosLayout infosTitle={infosTitle} className="totalAmountInfos">
            <Box className="infosWrapper">
                <Box className="mainInfosItemsWrapper">
                    <Box className="leftInfos">
                        <ItemFieldList
                            listInfos={leftInfos}
                            fieldData={tradingData}
                        />
                    </Box>
                </Box>
                <RemarkMsgs remarkMsgs={remarkMsgs} />
            </Box>
        </InfosLayout>
    );
}
